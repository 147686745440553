.bloks {
  border-right: 2px solid #a1a1a1;
  margin-bottom: 20px;
}

.bloks:last-child {
  border-right: 0;
}

.bloks > h3 {
  text-align: center;
}

.bloksItem {
  padding: 20px 30px;
}

.card_image {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  border-radius: 3px 3px 0 0;
  float: left;
  margin-right: 5px;
}

.card_image_id {
  background-color: #ffffffb3;
  padding: 2px 10px;
  display: inline-block;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 7px;
  font-weight: 900;
  font-size: 0.7rem;
  position: relative;
  top: -3px;
  left: 0;
}

.imgLoad {
  width: 300px;
  img {
    width: inherit;
  }
  button {
    width: inherit;
  }
}

.imgListElement {
  width: 200px;
  height: 200px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.3em;
}
.imgListElementCross {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background: #ffffffc9;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
  border-end-start-radius: 0.3em;
}
.imgListAdd {
  width: 100%;
  height: 100%;
  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background: var(--bs-success);
    border-radius: 0.3em;
    border: none;
    svg {
      color: white;
      margin: auto;
      font-size: 6em;
    }
  }
}
.imgListGenerate {
  width: 100%;
  height: 100%;
  button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background: var(--bs-warning);
    border-radius: 0.3em;
    border: none;
    svg {
      color: white;
      margin: auto;
      font-size: 6em;
    }
  }
}
.imgGeneratedElement {
  width: 400px;
  height: 400px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 0.3em;
}

@media screen and (max-width: 900px) {
  .imgListElement {
    width: 100px;
    height: 100px;
  }
  .imgListAdd {
    button {
      width: 100px;
      height: 100px;
      svg {
        font-size: 2em;
      }
    }
  }
}

.personDescription {
  flex: 0 !important;
}
.personDescription > div {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-size: cover;
}

.textEditor {
  height: 400px;
  border: 1px solid #fd8a009e !important;
  background-color: #fff8ee !important;

  label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .container {
    position: relative;
    font-size: 1.25rem;
  }
}
.carouselContainer {
  display: inline-block;
  float: left;
  margin-right: 1rem;
}

.textImage {
  width: 50%;
  height: auto;
  float: left;
  margin-right: 1rem;
}

.userListAva {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-radius: 50%;
  margin-right: -10px;
  margin-bottom: -10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeFolder {
  border-radius: 5px;
  font-weight: bold;
}

.generationPersonPresetImage,
.generationQuestPresetImage,
.generationLocationPresetImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 200px;
  height: 200px;
  float: left;
  margin: 5px 10px 5px 0;
  border-radius: 6px;
}

.generationPersonPresetSpiner,
.generationQuestPresetSpiner,
.generationLocationPresetSpiner {
  width: 200px;
  height: 200px;
  float: left;
  margin: 5px 10px 5px 0;
  display: flex;
  background-color: #f1f0f0;
  border-radius: 6px;
}

.draftCard {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fbc57d;
  border-top-left-radius: 7px;
  font-size: 11px;
  padding: 0px 6px;
  font-weight: bold;
}

.statusesCard {
  position: absolute;
  bottom: 3px;
  left: 3px;
  font-size: 9px;
  padding: 1px 1px;
  display: flex;

  div {
    border: 1px solid #c7c7c7;
    background: #dfdbfd;
    border-radius: 5px;
    margin-right: 3px;
    padding: 1px 3px;
    font-weight: bold;
  }
}

div.statusesCardAI {
  background: #ffc107;
  border: 0;
}

.draftCardTable {
  margin-top: 5px;
  background: #fbc57d;
  border-radius: 7px;
  font-size: 12px;
  padding: 1px 6px;
  font-weight: bold;
}

.statusCardTable {
  margin-top: 5px;
  background: #dfdbfd;
  border: 1px solid #c7c7c7;
  border-radius: 7px;
  font-size: 12px;
  padding: 1px 6px;
  font-weight: bold;
}

.statusCardTableAI {
  margin-top: 5px;
  background: #ffc107;
  border-radius: 7px;
  font-size: 12px;
  padding: 1px 6px;
  font-weight: bold;
}

.customLogoutButton {
  border: 0;
  background: transparent;
  padding: 0;
}

.uploadScreenShot {
  width: 100%;
  height: 400px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: transparent;
}
